import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { ChevronRight, Building2, Ruler, Compass, Building, HardHat, ClipboardList } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import eng from "../Assets/eng2.jpg"
import ach from "../Assets/work.jpg"
import cost from "../Assets/team4.jpg"

const services = [
  {
    id: 'architecture',
    title: 'Architecture',
    icon: Building2,
    description: 'We craft innovative and functional designs that harmonize with their surroundings and reflect our clients\' visions. From conceptual design to detailed documentation, our architectural services are tailored to create spaces that inspire and engage.',
    features: ['Conceptual Design', 'Detailed Documentation', '3D & 2D Drawings', 'Sustainable Design'],
    image: ach,
  },
  {
    id: 'engineering',
    title: 'Engineering',
    icon: Compass,
    description: 'Our engineering team integrates structural, mechanical, and electrical systems to ensure the seamless functionality and safety of our projects. We prioritize efficiency, sustainability, and technical excellence in every engineering solution.',
    features: ['Structural Engineering', 'MEP Systems', 'Safety Analysis', 'Technical Excellence'],
    image: eng,
  },
    {
    id: 'project-management',
    title: 'Project Management',
    icon: ClipboardList,
    description: 'Delivering projects on time, within budget, and exceeding expectations through strategic planning and execution. Our comprehensive approach ensures seamless coordination and successful delivery of construction projects of all sizes.',
    features: ['Strategic Planning', 'Resource Optimization', 'Risk Management', 'Quality Control', 'Timeline Management'],
    image: "https://images.pexels.com/photos/7376/startup-photos.jpg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    id: 'town-planning',
    title: 'Town Planning',
    icon: Building,
    description: 'We offer expert town planning services to help shape the development of communities and urban spaces. Our approach includes strategic planning, zoning analysis, and sustainable development practices.',
    features: ['Property Consolidation','Urban Design', 'Zoning Analysis', 'Sustainable Planning', 'Change of Use Permits'],
    image: 'https://images.pexels.com/photos/7937310/pexels-photo-7937310.jpeg?auto=compress&cs=tinysrgb&w=600'
  },
  {
    id: 'quantity-surveying',
    title: 'Quantity Surveying',
    icon: Ruler,
    description: 'Our quantity surveying services provide precise cost management and financial oversight throughout the project lifecycle. We ensure budget adherence, cost forecasting, and value engineering to optimize resource allocation.',
    features: ['Cost Management', 'Value Engineering', 'Contract Administration', 'Risk Assessment'],
    image: 'https://images.pexels.com/photos/4832564/pexels-photo-4832564.jpeg?auto=compress&cs=tinysrgb&w=600'
  },
  {
    id: 'construction',
    title: 'Construction',
    icon: HardHat,
    description: 'From project management to on-site supervision, our construction services are designed to bring architectural visions to life. We collaborate closely with clients and contractors to ensure high standards of quality, safety, and timely delivery.',
    features: ['Project Management', 'Quality Control', 'Safety Compliance', 'Timeline Optimization'],
    image: cost,
  },
];

export const OurServices = ({title}) => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <main className="min-h-screen bg-gray-50">
      {/* Hero Section */}
      <section className="relative py-20 bg-gradient-to-br from-blue-800 to-cyan-300 text-black">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <h1 className="text-4xl font-bold mb-4">Our Services</h1>
            <p className="text-xl text-yellow-400 max-w-3xl mx-auto">
              At BuildFast Structural Solutions, we provide a comprehensive range of
              services to address every aspect of the design and construction process.
              Our multidisciplinary approach ensures that each project benefits from
              expert insight and seamless coordination across all phases.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <motion.div
                key={service.title}
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white rounded-lg overflow-hidden shadow-lg flex flex-col"
              >
                <div className="h-64 relative">
                  <img 
                    src={service.image}
                    alt={service.title}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </div>
                <div className="p-6 flex-grow">
                  <div className="flex items-center mb-4">
                    <service.icon className="w-8 h-8 text-blue-600 mr-3" />
                    <h2 className="text-2xl font-bold text-gray-900">{service.title}</h2>
                  </div>
                  <p className="text-gray-600 mb-6">{service.description}</p>
                  <div className="mb-6">
                    <h3 className="text-lg font-semibold text-gray-900 mb-3">Key Features:</h3>
                    <ul className="space-y-2">
                      {service.features.map((feature, featureIndex) => (
                        <li key={featureIndex} className="flex items-center text-gray-600">
                          <ChevronRight className="w-4 h-4 text-blue-600 mr-2 flex-shrink-0" />
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="p-6 pt-0">
                  <button
                    onClick={() => navigate(`/services/${service.title.toLowerCase().replace(' ', '-')}`)}
                    className="w-full bg-yellow-400 hover:bg-yellow-500 text-blue-800 font-bold py-3 px-6 rounded-lg transition duration-300 flex items-center justify-center"
                  >
                    Learn More
                    <ChevronRight className="ml-2" size={20} />
                  </button>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 px-4 sm:px-6 lg:px-8 bg-gradient-to-br from-blue-50 to-cyan-100">
        <div className="max-w-7xl mx-auto text-center">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Ready to Start Your Project?</h2>
            <p className="text-xl text-gray-600 mb-8">
              Let's bring your vision to life. Contact us today for a consultation.
            </p>
            <button
              onClick={() => navigate('/contact-us')}
              className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-8 rounded-lg transition duration-300"
            >
              Get in Touch
            </button>
          </motion.div>
        </div>
      </section>
    </main>
  );
};


