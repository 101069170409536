import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRight, Briefcase, Users, Clock } from 'lucide-react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import { storage } from '../components/config/firebase'; // You'll need to create this
import { ref, listAll, getDownloadURL } from 'firebase/storage';
import 'swiper/css';
import 'swiper/css/pagination';
import team from "../Assets/team.jpeg"
import eng from "../Assets/eng.jpg"
import work from "../Assets/work.jpg"
import quility from "../Assets/eng2.jpg"
import teamwork from "../Assets/team15.jpg"

const projectShowcase = [
  {
    title: "Innovative Design Solutions",
    description: "Our team collaboratively developing cutting-edge design concepts",
    image: work,
    category: "Design"
  },
  {
    title: "Precision Engineering",
    description: "Implementing complex technical solutions with precision",
    image: eng,
    category: "Engineering"
  },
  {
    title: "Quality Assurance",
    description: "Rigorous testing and verification processes in action",
    image: quility,
    category: "Quality Control"
  },
  {
    title: "Project Implementation",
    description: "Bringing designs to life with expert execution",
    image: teamwork,
    category: "Implementation"
  }
];

const stats = [
  { icon: Briefcase, value: '200+', label: 'Projects Completed' },
  { icon: Users, value: '50+', label: 'Team Members' },
  { icon: Clock, value: '5+', label: 'Years Experience' },
];

export const TeamPage = ({ title = "Our Team" }) => {
  const [teamPhotos, setTeamPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = title;
    fetchTeamPhotos();
  }, [title]);

  const fetchTeamPhotos = async () => {
    try {
      const imagesRef = ref(storage, 'team-photos');
      const imagesList = await listAll(imagesRef);
      
      const urlPromises = imagesList.items.map(async (imageRef) => {
        const url = await getDownloadURL(imageRef);
        return {
          src: url,
          alt: `Team at work - ${imageRef.name}`
        };
      });

      const photos = await Promise.all(urlPromises);
      setTeamPhotos(photos);
    } catch (error) {
      console.error("Error fetching team photos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleButton = () => {
    navigate('./contact-us');
  };

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="text-2xl text-gray-600">Loading team photos...</div>
      </div>
    );
  }

  return (
    <main className="overflow-x-hidden">
      {/* Hero Section */}
      <section className="relative h-[70vh] bg-gray-900 flex items-center justify-center text-white">
        <div className="absolute inset-0 overflow-hidden">
          <img 
            src={team}
            alt="Team at work" 
            className="w-full h-full object-cover opacity-30"
          />
        </div>
        <div className="text-center relative z-10 px-4">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">Excellence in Action</h1>
          <p className="text-xl md:text-2xl mb-6 max-w-2xl mx-auto">
            Discover how our dedicated team delivers exceptional results through innovation and expertise.
          </p>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {stats.map((stat, index) => (
              <div key={index} className="text-center">
                <stat.icon className="mx-auto h-12 w-12 text-blue-500 mb-4" />
                <h2 className="text-4xl font-bold text-gray-900 mb-2">{stat.value}</h2>
                <p className="text-lg text-gray-600">{stat.label}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Team Gallery Section */}
      {teamPhotos.length > 0 && (
        <section className="py-16 px-4 bg-gray-50">
          <div className="max-w-7xl mx-auto">
            <div>
              <h2 className="text-3xl font-bold mb-2 text-center">Our Team in Action</h2>
              <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">
                Witness our passionate team bringing expertise to every project.
              </p>
            </div>
            
            <Swiper
              modules={[Pagination, Autoplay]}
              spaceBetween={20}
              slidesPerView={1}
              breakpoints={{
                640: { slidesPerView: 2 },
                768: { slidesPerView: 3 },
                1024: { slidesPerView: 4 },
              }}
              pagination={{ clickable: true }}
              autoplay={{ delay: 3000 }}
              loop={teamPhotos.length > 4}
              className="team-gallery-swiper"
            >
              {teamPhotos.map((photo, index) => (
                <SwiperSlide key={index}>
                  <div className="relative overflow-hidden rounded-lg aspect-square">
                    <img 
                      src={photo.src}
                      alt={photo.alt}
                      className="w-full h-full object-cover transition-transform duration-300 hover:scale-110"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </section>
      )}

      {/* Our Work Section */}
      <section className="py-16 px-4 bg-white">
        <div className="max-w-7xl mx-auto">
          <div>
            <h2 className="text-3xl font-bold mb-2 text-center">Our Work</h2>
            <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">
              Explore the diverse range of projects and solutions our team delivers.
            </p>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {projectShowcase.map((project, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-lg overflow-hidden"
              >
                <img
                  className="w-full h-64 object-cover"
                  src={project.image}
                  alt={project.title}
                />
                <div className="p-6">
                  <span className="text-blue-500 font-semibold text-sm mb-2 block">
                    {project.category}
                  </span>
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{project.title}</h3>
                  <p className="text-gray-600">{project.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Rest of the sections remain the same */}
      
      {/* Contact Section */}
      <section className="py-16 bg-gray-900 text-white">
        <div className="max-w-7xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to Start Your Project?</h2>
          <p className="mb-8 text-lg max-w-2xl mx-auto">
            Let our team help bring your vision to life. Contact us to discuss your needs.
          </p>
          <button
            onClick={handleButton}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-4 px-8 rounded-lg text-lg inline-flex items-center"
          >
            Get In Touch <ChevronRight className="ml-2" size={20} />
          </button>
        </div>
      </section>
    </main>
  );
};