import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Phone, Mail, MapPin, Send, Building, Users, ChevronDown } from 'lucide-react';

const countryCodes = [
  { code: '+263', country: 'Zimbabwe' },
  { code: '+27', country: 'South Africa' },
  { code: '+260', country: 'Zambia' },
  { code: '+267', country: 'Botswana' },
  { code: '+258', country: 'Mozambique' },
];

export const Contacts = ({title}) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  
  const [formStatus, setFormStatus] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('+263');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isCountryDropdownOpen, setIsCountryDropdownOpen] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    formData.set('phone', `${selectedCountryCode}${phoneNumber}`);
    
    try {
      const response = await fetch("https://formspree.io/f/xqazrgwy", {
        method: "POST",
        body: formData,
        headers: { 'Accept': 'application/json' }
      });
      
      if (response.ok) {
        setFormStatus({ type: "success", message: "Message sent successfully!" });
        form.reset();
        setPhoneNumber('');
      } else {
        setFormStatus({ type: "error", message: "Failed to send message. Please try again." });
      }
    } catch (error) {
      setFormStatus({ type: "error", message: "An error occurred. Please try again later." });
    }
  };

  const contactPeople = [
    // { name: 'John Doe', role: 'CEO', phone: '+263 77 123 4567', email: 'john@buildfast.co.zw' },
    // { name: 'Jane Smith', role: 'Project Manager', phone: '+263 77 234 5678', email: 'jane@buildfast.co.zw' },
    // { name: 'Mike Johnson', role: 'Lead Architect', phone: '+263 77 345 6789', email: 'mike@buildfast.co.zw' },
  ];

  return (
    <main className="bg-gradient-to-b from-gray-900 to-gray-800 text-white min-h-screen">
      <div className="max-w-7xl mx-auto px-4 py-8 sm:py-16 sm:px-6 lg:px-8">
        <motion.h1 
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="text-3xl sm:text-4xl lg:text-5xl font-bold text-center mb-8 sm:mb-16 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-cyan-400"
        >
          Get in Touch
        </motion.h1>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Contact Form Card */}
          <motion.div 
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            className="lg:col-span-2"
          >
            <div className="bg-gray-800/50 border border-gray-700 rounded-lg p-4 sm:p-6">
              <h2 className="text-xl sm:text-2xl font-semibold mb-6">Send Us a Message</h2>
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div>
                    <input
                      type="text"
                      placeholder="Your Name"
                      name="name"
                      required
                      className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:border-blue-500 transition-colors"
                    />
                  </div>
                  <div>
                    <input
                      type="email"
                      placeholder="Your Email"
                      name="email"
                      required
                      className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:border-blue-500 transition-colors"
                    />
                  </div>
                </div>
                
                {/* Responsive phone input */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                  <div className="relative">
                    <button
                      type="button"
                      className="flex items-center justify-between w-full sm:w-[140px] px-4 py-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:outline-none focus:border-blue-500 transition-colors"
                      onClick={() => setIsCountryDropdownOpen(!isCountryDropdownOpen)}
                    >
                      <span>{selectedCountryCode}</span>
                      <ChevronDown className="ml-2 h-4 w-4" />
                    </button>
                    {isCountryDropdownOpen && (
                      <div className="absolute z-10 w-full mt-1 bg-gray-700 border border-gray-600 rounded-md shadow-lg">
                        {countryCodes.map(({code, country}) => (
                          <button
                            key={code}
                            type="button"
                            className="block w-full px-4 py-2 text-left hover:bg-gray-600 focus:outline-none"
                            onClick={() => {
                              setSelectedCountryCode(code);
                              setIsCountryDropdownOpen(false);
                            }}
                          >
                            {code} {country}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                  <input
                    type="tel"
                    placeholder="Phone Number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:border-blue-500 transition-colors"
                  />
                </div>
                
                <input
                  type="text"
                  placeholder="Subject"
                  name="subject"
                  required
                  className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:border-blue-500 transition-colors"
                />
                
                <textarea
                  placeholder="Your Message"
                  name="message"
                  rows={6}
                  required
                  className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-md text-white placeholder-gray-400 focus:outline-none focus:border-blue-500 transition-colors resize-none"
                />
                
                <button 
                  type="submit" 
                  className="w-full px-6 py-3 bg-blue-600 hover:bg-blue-700 rounded-md font-semibold flex items-center justify-center transition-colors"
                >
                  <Send className="mr-2 h-4 w-4" /> Send Message
                </button>
                
                {formStatus && (
                  <p className={`text-center ${formStatus.type === 'success' ? 'text-green-400' : 'text-red-400'}`}>
                    {formStatus.message}
                  </p>
                )}
              </form>
            </div>
          </motion.div>

          {/* Contact Info Cards */}
          <motion.div 
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            className="space-y-6"
          >
            <div className="bg-gray-800/50 border border-gray-700 rounded-lg p-4 sm:p-6">
              <h2 className="text-xl font-semibold mb-4 flex items-center">
                <Building className="mr-2 text-blue-400" /> Head Office
              </h2>
              <div className="space-y-3">
                <p className="flex items-center">
                  <MapPin className="mr-2 text-blue-400 flex-shrink-0" />
                  <span>Margoliz Plaza, Harare</span>
                </p>
                <p className="flex items-center">
                  <Phone className="mr-2 text-blue-400 flex-shrink-0" />
                  <span>+263 776 347 735</span>
                </p>
                <p className="flex items-center">
                  <Mail className="mr-2 text-blue-400 flex-shrink-0" />
                  <span className="break-all">sales@buildfast.co.zw</span>
                </p>
              </div>
            </div>

            <div className="bg-gray-800/50 border border-gray-700 rounded-lg p-4 sm:p-6">
              <h2 className="text-xl font-semibold mb-4 flex items-center">
                <Users className="mr-2 text-blue-400" /> Key Contacts
              </h2>
              <div className="space-y-4">
                {contactPeople.map((person, index) => (
                  <div key={index} className="border-b border-gray-700 last:border-0 pb-2">
                    <p className="font-medium">{person.name}</p>
                    <p className="text-sm text-gray-400">{person.role}</p>
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        </div>

        {/* Map Section */}
        <motion.div 
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="mt-8 sm:mt-16"
        >
          <h2 className="text-xl sm:text-2xl font-semibold mb-6 text-center">Our Location</h2>
          <div className="bg-gray-800/50 border border-gray-700 rounded-lg overflow-hidden">
            <iframe
              title="Office Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3798.225517258067!2d31.047399015353085!3d-17.828917987827373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1931a4f3f06beccd%3A0x1a82d14d840b163d!2sHarare%20St%20%26%20Speke%20Ave%2C%20Harare%2C%20Zimbabwe!5e0!3m2!1sen!2s!4v1645784892834!5m2!1sen!2s"
              className="w-full h-[300px] sm:h-[400px]"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            />
          </div>
        </motion.div>
      </div>
    </main>
  );
};