/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import background from "../Assets/back.png"
import { Award, Target, Eye, Compass, TrendingUp, Shield, ChevronRight, CheckCircle } from 'lucide-react';

const values = [
  {
    icon: <Target className="w-12 h-12 text-blue-600" />,
    title: "Innovation",
    description: "Embrace creativity and forward-thinking solutions to push the boundaries of design and functionality. "
  },
  {
    icon: <Shield className="w-12 h-12 text-blue-600" />,
    title: "Quality",
    description: "Unwavering commitment to excellence in every aspect of our work."
  },
  {
    icon: <Award className="w-12 h-12 text-blue-600" />,
    title: "Excellence",
    description: "Commit to high standards in design,craftsmanship, and client service to deliver superior results."
  },
  {
    icon: <TrendingUp className="w-12 h-12 text-blue-600" />,
    title: "Sustainability",
    description: "Prioritize eco-friendly practices and materials to ensure that our projects contribute positively to the environment."
  },
  {
    icon: <ChevronRight className="w-12 h-12 text-blue-600" />,
    title: "Collaboration",
    description: "Foster a team-oriented culture that values input from all stakeholders to achieve the best possible outcomes."
  },
  {
    icon: <CheckCircle className="!w-12 !h-12 !text-blue-600" />,
    title: "Integrity",
    description: "Maintain transparency, honesty and ethical standards in all business practices and client interactions."
  }
];

const milestones = [
  {
    year: "2018",
    event: "Company Founded",
    description: "BuildFast Structural Solutions was established in Harare."
  },
  {
    year: "2020",
    event: "First Major Project",
    description: "Completed our first commercial building project in the CBD."
  },
  {
    year: "2022",
    event: "Expansion",
    description: "Opened additional office and expanded services to include engineering."
  },
  {
    year: "2023",
    event: "Industry Recognition",
    description: "Received multiple awards for architectural excellence and innovation."
  }
];

export const About = ({title}) => {
  useEffect(() => {
  document.title = title;
}, [title]);
  return (
    <div className="w-full overflow-x-hidden">
      {/* Hero Section */}
      <section
        className="relative h-screen flex items-center justify-center"
        style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundColor: 'rgb(0 0 0 / 50%)' }}
      >
        <div className="absolute inset-0 bg-black/30"></div> {/* Reduced opacity */}
        <div className="relative z-10 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto text-center">
          <motion.h1
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="text-2xl sm:text-5xl lg:text-2xl bg-slate-100 font-bold text-blue-800 mb-6"
          >
            About BuildFast Structural Solutions
          </motion.h1>
          <motion.p
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="text-xl sm:text-2xl bg-slate-100 text-blue-800 max-w-3xl mx-auto"
          >
            Excellence in Architecture & Engineering Since 2018
          </motion.p>
        </div>
      </section>

      {/* Company Overview Section */}
      <section className="py-16 sm:py-24 px-4 sm:px-6 lg:px-8 bg-white">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-col lg:flex-row gap-12 items-center">
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="flex-1 space-y-6"
            >
              <h2 className="text-3xl sm:text-4xl h-8 font-bold text-blue-600">Our Story</h2>
              <div className="space-y-4 text-gray-700">
                <p>
                  Welcome to BuildFast Structural Solutions, where architecture meets innovation and creativity. Founded on a passion for design, excellence and a commitment to sustainability, our firm is dedicated to crafting spaces that inspire, engage, and transform.
                </p>
                <p>
                  With a team of visionary architects and design professionals, we specialize in creating dynamic environments that blend functionality with aesthetic appeal. Our approach is rooted in a deep understanding of our clients’ needs and aspirations, combined with a commitment to pushing the boundaries of conventional design
                </p>
                <p>
                  At BuildFast Structural Solutions, we believe that every project is an opportunity to make a positive impact. Whether designing residential, commercial, or civic spaces, our goal is to deliver solutions that are not only visually stunning but also environmentally responsible and future-focused.
                </p>
                <p>
                  Explore our portfolio to see how we turn ideas into exceptional architectural experiences, and join us in shaping the spaces of tomorrow. .
                </p>
              </div>
              <div className="mt-8">
                <h3 className="text-xl font-semibold mb-4 text-blue-600">Why Choose Us</h3>
                <div className="flex flex-col space-y-3">
                  {[
                    "Innovative design solutions",
                    "Comprehensive project management",
                    "Sustainable building practices",
                    "Award-winning architectural team"
                  ].map((item, index) => (
                    <div key={index} className="flex items-center space-x-3">
                      <CheckCircle className="w-8 h-8 text-bue-600 flex-shrink-0" />
                      <span className="text-gray-700">{item}</span>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ x: 50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="flex-1 flex flex-wrap gap-4"
            >
              <img src="https://images.pexels.com/photos/225502/pexels-photo-225502.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Company office" className="w-[calc(50%-8px)] rounded-lg shadow-lg" />
              <img src="https://images.pexels.com/photos/5611713/pexels-photo-5611713.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Project in progress" className="w-[calc(50%-8px)] rounded-lg shadow-lg" />
              <img src="https://images.pexels.com/photos/224924/pexels-photo-224924.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Completed building" className="w-[calc(50%-8px)] rounded-lg shadow-lg" />
              <img src="https://images.pexels.com/photos/271667/pexels-photo-271667.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Design team at work" className="w-[calc(50%-8px)] rounded-lg shadow-lg" />
            </motion.div>
          </div>
        </div>
        {/* Vision & Mission Section - Add this after the Hero section */}
        <section className="py-16 sm:py-24 gap-4 px-4 mt-4 sm:px-6 lg:px-8 bg-gray-50">
          <div className="max-w-7xl gap-3 mx-auto">
            <div className="md:flex md:flex-row sm:flex-col gap-4">
              {/* Vision Card */}
              <motion.div
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
                className="mt-4 p-8"
              >
                <div className="flex items-center mb-6">
                  <Eye className="w-12 h-12 text-blue-600 mr-4" />
                  <h2 className="text-3xl font-bold text-gray-900">Our Vision</h2>
                </div>
                <p className="text-gray-700 text-lg leading-relaxed">
                  To be a leading architectural firm renowned for
                  transforming visions into reality with cutting-edge
                  design solutions that harmonize aesthetics,
                  functionality, and sustainability, shaping a better
                  future for communities around the world.
                </p>
              </motion.div>

              {/* Mission Card */}
              <motion.div
                initial={{ x: 50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
                className="mt-4 p-8"
              >
                <div className="flex items-center mb-6">
                  <Compass className="w-12 h-12 text-blue-600 mr-4" />
                  <h2 className="text-3xl font-bold text-gray-900">Our Mission</h2>
                </div>
                <p className="text-gray-700 text-lg leading-relaxed">
                  To create inspiring and sustainable spaces through innovative design, meticulous planning, and collaborative partnerships, enhancing the built environment and enriching the lives of those who
                  interact with our creations. We are committed to excellence in every project, from concept to completion, and to fostering a culture of creativity, integrity, and environmental stewardship.
                </p>
                <ul className="mt-4 space-y-2">
                  {[
                    "Creating functional and aesthetically pleasing spaces",
                    "Implementing sustainable building practices",
                    "Fostering collaborative relationships with clients",
                    "Pushing the boundaries of architectural innovation"
                  ].map((item, index) => (
                    <li key={index} className="flex items-center text-gray-700">
                      <CheckCircle className="w-5 h-5 text-blue-600 mr-2 flex-shrink-0" />
                      {item}
                    </li>
                  ))}
                </ul>
              </motion.div>
            </div>
          </div>
        </section>
      </section>

      {/* Our Values Section */}
      <section className="py-16 sm:py-24 px-4 sm:px-6 lg:px-8 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="text-center mb-12"
          >
            <h2 className="text-3xl sm:text-4xl font-bold text-gray-900">Our Core Values</h2>
          </motion.div>
          <div className="flex flex-wrap justify-center gap-8">
            {values.map((value, index) => (
              <motion.div
                key={index}
                initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: index * 0.1 }}
                className="flex flex-col items-center w-full sm:w-[calc(50%-16px)] lg:w-[calc(25%-24px)]"
              >
                <div className="mb-4">{value.icon}</div>
                <h3 className="text-xl font-semibold mb-2 text-gray-900">{value.title}</h3>
                <p className="text-gray-700 text-center">{value.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Milestones Section */}
      <section className="py-16 sm:py-24 px-4 my-4 sm:px-6 lg:px-8 gap-8 bg-white">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="text-center gap-2 mb-12"
          >
            <h2 className="text-3xl sm:text-4xl font-bold text-gray-900">Our Journey</h2>
          </motion.div>
          <div className="flex flex-col space-y-12">
            {milestones.map((milestone, index) => (
              <motion.div
                key={index}
                initial={{ x: index % 2 === 0 ? -50 : 50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.8 }}
                className={`flex flex-col ${index % 2 === 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'} items-center gap-8`}
              >
                <div className="flex-1 flex justify-center">
                  <div className="w-20 h-20 rounded-full bg-blue-600 flex items-center justify-center text-white text-2xl font-bold">
                    {milestone.year}
                  </div>
                </div>
                <div className="flex-1">
                  <div className="bg-gray-50 p-6 rounded-lg shadow-lg">
                    <h3 className="text-xl font-semibold mb-2 text-gray-900">{milestone.event}</h3>
                    <p className="text-gray-700">{milestone.description}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Achievements Section */}
      <section className="py-16 sm:py-24 px-4 gap-8 sm:px-6 lg:px-8 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <h2 className="text-3xl sm:text-4xl font-bold mb-12 text-gray-900">Our Achievements</h2>
            <div className="flex flex-wrap justify-center gap-8">
              {[
                { number: "200+", label: "Projects Completed" },
                { number: "5+", label: "Years of Experience" },
                { number: "5+", label: "Industry Awards" },
                { number: "100%", label: "Client Satisfaction" }
              ].map((achievement, index) => (
                <motion.div
                  key={index}
                  initial={{ y: 50, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.8, delay: index * 0.1 }}
                  className="w-full sm:w-[calc(50%-16px)] lg:w-[calc(25%-24px)] text-center"
                >
                  <h3 className="text-4xl font-bold text-blue-600 mb-2">{achievement.number}</h3>
                  <p className="text-gray-700">{achievement.label}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

// Add this CSS to your global stylesheet or in a <style> tag in your HTML
const globalStyles = `
  html {
    scroll-behavior: smooth;
  }
`;

// Inject the global styles into the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = globalStyles;
document.head.appendChild(styleSheet);
