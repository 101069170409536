import React, { useState, useEffect } from 'react';
import { storage, auth } from '../components/config/firebase';
import { ref, listAll, getDownloadURL, deleteObject } from 'firebase/storage';
import { onAuthStateChanged } from 'firebase/auth';
import { ImageIcon, Loader, Upload, Trash2, ZoomIn, Download } from 'lucide-react';
import { Link } from 'react-router-dom';

export const Gallery = ({title}) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);

    useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
    });

    fetchImages();

    return () => unsubscribe();
  }, []);

  const fetchImages = async () => {
    setLoading(true);
    setError(null);
    try {
      const imagesRef = ref(storage, 'uploads');
      const result = await listAll(imagesRef);
      
      const urlPromises = result.items.map(async (item) => {
        try {
          const url = await getDownloadURL(item);
          return {
            url,
            name: item.name,
            fullPath: item.fullPath
          };
        } catch (err) {
          console.error(`Error fetching URL for ${item.name}:`, err);
          return null;
        }
      });

      const imageUrls = (await Promise.all(urlPromises)).filter(item => item !== null);
      setImages(imageUrls);
    } catch (err) {
      console.error('Error fetching images:', err);
      setError('Failed to fetch images. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (image, e) => {
    e.stopPropagation(); // Prevent opening the modal
    if (!user) return;
    
    if (window.confirm('Are you sure you want to delete this image?')) {
      setDeleteLoading(true);
      try {
        const imageRef = ref(storage, image.fullPath);
        await deleteObject(imageRef);
        setImages(prevImages => prevImages.filter(img => img.fullPath !== image.fullPath));
      } catch (err) {
        console.error('Error deleting image:', err);
        alert('Failed to delete image. Please try again.');
      } finally {
        setDeleteLoading(false);
      }
    }
  };

  const downloadImage = async (image, e) => {
    e.stopPropagation(); // Prevent opening the modal
    try {
      const response = await fetch(image.url);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = image.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
      console.error('Error downloading image:', err);
      alert('Failed to download image. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <Loader className="w-8 h-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 gap-4">
        <h2 className="text-2xl font-bold">Image Gallery</h2>
        <div className="flex items-center gap-4">
          {error && (
            <button 
              onClick={fetchImages} 
              className="text-blue-500 hover:text-blue-700 transition-colors"
            >
              Retry
            </button>
          )}
        </div>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-6">
          <p>{error}</p>
        </div>
      )}
      
      {images.length === 0 ? (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <ImageIcon className="mx-auto h-12 w-12 text-gray-400" />
          <h3 className="mt-2 text-sm font-semibold text-gray-900">No images yet</h3>
          <p className="mt-1 text-sm text-gray-500">
            {user ? 'Be the first to upload an image!' : 'Images uploaded will appear here.'}
          </p>
          {user && (
            <Link 
              to="/upload"
              className="mt-6 inline-flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
            >
              <Upload className="w-4 h-4" />
              Upload an Image
            </Link>
          )}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {images.map((image, index) => (
            <div 
              key={index} 
              className="relative group cursor-pointer"
              onClick={() => setSelectedImage(image)}
            >
              <div className="aspect-square overflow-hidden rounded-lg bg-gray-100">
                <img 
                  src={image.url} 
                  alt={image.name}
                  className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-110"
                />
              </div>
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-opacity duration-300 rounded-lg flex items-center justify-center">
                <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex gap-2">
                  <button
                    onClick={(e) => downloadImage(image, e)}
                    className="p-2 bg-white rounded-full hover:bg-gray-100 transition-colors"
                    title="Download image"
                  >
                    <Download className="w-5 h-5 text-gray-700" />
                  </button>
                  <button
                    onClick={() => setSelectedImage(image)}
                    className="p-2 bg-white rounded-full hover:bg-gray-100 transition-colors"
                    title="View image"
                  >
                    <ZoomIn className="w-5 h-5 text-gray-700" />
                  </button>
                  {user && (
                    <button
                      onClick={(e) => handleDelete(image, e)}
                      className="p-2 bg-white rounded-full hover:bg-red-100 transition-colors"
                      title="Delete image"
                      disabled={deleteLoading}
                    >
                      <Trash2 className="w-5 h-5 text-red-600" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {selectedImage && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
          onClick={() => setSelectedImage(null)}
        >
          <div 
            className="max-w-4xl w-full bg-white rounded-lg overflow-hidden shadow-xl"
            onClick={e => e.stopPropagation()}
          >
            <div className="relative">
              <img 
                src={selectedImage.url} 
                alt={selectedImage.name}
                className="max-h-[80vh] mx-auto"
              />
              <button 
                onClick={() => setSelectedImage(null)}
                className="absolute top-4 right-4 text-white bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-75 transition-opacity"
              >
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div className="p-4 bg-white">
              <h3 className="text-lg font-semibold mb-2">{selectedImage.name}</h3>
              <div className="flex gap-4">
                <button
                  onClick={(e) => downloadImage(selectedImage, e)}
                  className="flex items-center gap-2 text-blue-500 hover:text-blue-700 transition-colors"
                >
                  <Download className="w-5 h-5" />
                  Download
                </button>
                {user && (
                  <button
                    onClick={(e) => handleDelete(selectedImage, e)}
                    className="flex items-center gap-2 text-red-500 hover:text-red-700 transition-colors"
                    disabled={deleteLoading}
                  >
                    <Trash2 className="w-5 h-5" />
                    Delete
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
