import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AllRoutes } from './routes/AllRoutes';
import { Header, Footer } from './components';
import { ScrollToTop } from './components';
import { Helmet } from 'react-helmet'; // Import react-helmet
import './App.css';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>BuildFast Structural Solutions - Innovative Architecture</title>
        <meta
          name="description"
          content="BuildFast Structural Solutions offers innovative and sustainable architectural services. Explore our portfolio of commercial, residential, and civic projects."
        />
        <link rel="canonical" href="https://build-fast.co.zw/" />
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "BuildFast Structural Solutions",
            "url": "https://build-fast.co.zw/",
            "logo": "https://build-fast.co.zw/logo192.png",  // Update this with the actual logo URL
            "description": "BuildFast Structural Solutions offers innovative and sustainable architectural services.",
            "sameAs": [
              "https://www.facebook.com/buildfastZIM",
              "https://www.instagram.com/buildfastzw/",
              "https://www.linkedin.com/in/courage-makonese-643687331",
              "https://www.tiktok.com/@build_fast"
            ],
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Margolis Plaza, Cnr Harare st & Speke Ave",
              "addressLocality": "Harare",
              "addressRegion": "Harare",
              "postalCode": "12345",
              "addressCountry": "Zimbabwe"
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+263 776 347 735",
              "contactType": "Customer Service"
            }
          }
          `}
        </script>
      </Helmet>
      <Router>
        <ScrollToTop />
        <Header />
        <AllRoutes />
        <Footer />
      </Router>
    </div>
  );
}

export default App;

